import React, { useEffect, useState } from 'react';

export const FirebaseSiteContent = ({ localStorageObject, contentPath }) => {
    const [contentBody, setContentBody] = useState(null);

    useEffect(() => {
        getContent(localStorageObject);
    }, [contentBody]);

    const resolveDataNode = (dataToRetrieve, dataObject) => {
        return dataToRetrieve.split('.').reduce(function (obj, objKey) {
            return obj && obj[objKey];
        }, dataObject);
    };

    const getContent = (localStorageObject) => {
        const db = window.localStorage;
        const resultObj = db.getItem(localStorageObject);

        if (resultObj) {
            const contentObject = JSON.parse(resultObj);
            const contentNode = resolveDataNode(contentPath, contentObject);
            setContentBody(contentNode);
        }
    };

    if (contentPath.includes('contentHTML')) {
        return (
            <div
                id={`cms-${localStorageObject}-${contentPath.replace(
                    /\./g,
                    '-'
                )}`}
                dangerouslySetInnerHTML={{ __html: contentBody }}
            />
        );
    } else if (contentPath.includes('cssBlock')) {
        return <style dangerouslySetInnerHTML={{ __html: contentBody }} />;
    } else {
        return contentBody;
    }
};
