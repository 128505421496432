import React from 'react';
import { FirebaseCMS, FirebaseSiteContent } from './components/FirebaseCMS';

export const cmsContent = (props) => {
    return FirebaseCMS(props);
};

export const SiteContent = ({ localStorageObject, contentPath }) => {
    return FirebaseSiteContent({ localStorageObject, contentPath });
};
