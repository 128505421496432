// this component requires the following props
// firebase configuration (object)
// collection name (string)
// document name or ID (string)

// if the firebase data is newer based on the timestamp from firebase,
// it will be returned as well as set in local storage

// The timestamp to use for comparison during preview mode testing is the
// lastUpdated timestamp. This is used because preview mode is not for published
// data, but simply saved data. For non-preview mode fetches, we compare to
// the lastPublished timestamp.

import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, where, doc, getDoc, getDocs, onSnapshot } from 'firebase/firestore';

const getDB = (cfg) => {
    const app = initializeApp(cfg);
    const db = getFirestore(app);
    return db;
};

const setAutoRefresh = (shouldWeRefresh, documentReference = null) => {
    // only set the listener on the second load of the data
    let firstSnapshotRetrieved = false;

    if (shouldWeRefresh && documentReference) {
        console.log('cms: Waiting for update signal from firebase/firestore');

        onSnapshot(documentReference, (snapshot) => {
            if (firstSnapshotRetrieved) {
                // reload the window and refresh the cache
                window.location.reload(true);
            }
            // we don't want to do this on the initial load
            // so, set that we already have the first snapshot
            firstSnapshotRetrieved = true;
        });
    }
};

const getData = async (props) => {
    const docTimestamp = {};
    let collectionRef = null;
    let documentRef = null;
    let docIdentifier = '';
    let dataUrl = '';
    const dataNode = props.previewMode === 'true' ? 'configModeUrl' : 'configUrl';
    const db = getDB(props.config);

    if (props.docId) {
        // give priority to the provided doc ID
        // uses the ID of the doc
        documentRef = await getDoc(doc(db, props.collection, props.docId));
        collectionRef = collection(db, props.collection);
        if (documentRef.exists()) {
            try {
                dataUrl = documentRef.data()[dataNode];
                docTimestamp.seconds =
                    props.previewMode === 'true'
                        ? parseInt(documentRef.data().lastUpdated.seconds)
                        : parseInt(documentRef.data().lastPublished.seconds);
            } catch (error) {
                console.error('Error getting document:', error);
            }
            docIdentifier = props.docId;
        } else {
            console.error('No such document,', props.docId);
        }
    } else if (props.docName) {
        try {
            // uses the name of the doc to query for
            collectionRef = collection(db, props.collection);
            documentRef = query(collectionRef, where('name', '==', props.docName));
            const querySnapshot = await getDocs(documentRef);

            if (querySnapshot.docs.length) {
                dataUrl = querySnapshot.docs[0].data()[dataNode];
                docTimestamp.seconds =
                    props.previewMode === 'true'
                        ? parseInt(querySnapshot.docs[0].data().lastUpdated.seconds)
                        : parseInt(querySnapshot.docs[0].data().lastPublished.seconds);
                docIdentifier = props.docName;
            } else {
                console.error('No such document,', props.docName);
            }
        } catch (error) {
            console.error('Error getting document:', error);
        }
    } else {
        console.error('Document reference missing');
        return null;
    }

    const dateObj = new Date(0);
    dateObj.setUTCSeconds(docTimestamp.seconds);
    docTimestamp.readable = dateObj.toString();

    // compare firebase data to localStorage data
    if (docIdentifier) {
        // console.log('cms: docIdentifier', docIdentifier);
        const localStorageContent = JSON.parse(window.localStorage.getItem(docIdentifier));

        setAutoRefresh(props.previewMode === 'true', documentRef);

        if (
            props.previewMode === window.localStorage.getItem('preview_mode') &&
            localStorageContent &&
            localStorageContent.published.seconds === docTimestamp.seconds
        ) {
            // if localStorage has the same timestamp, setCmsData with localStorage value
            return localStorageContent;
        } else {
            // clear local storage if we are in preview mode
            if (props.previewMode === 'true' && props.previewMode !== window.localStorage.getItem('preview_mode')) {
                window.localStorage.removeItem(docIdentifier);
            }

            // if firebase data has a different timestamp, setCmsData with firebase value
            // and set the new values in localStorage
            // note: we do not need to check if the firebase timestamp is greater, because
            // a different timestamp would always be greater, as time always moves forward

            const response = await fetch(dataUrl);
            const jsonData = await response.json();
            jsonData.published = docTimestamp;

            window.localStorage.setItem('preview_mode', props.previewMode);

            window.localStorage.setItem(docIdentifier, JSON.stringify(jsonData));

            // if the timestamp is different between the local storage and the firebase timestamp
            // issue a reload to re-render the site, as React does not monitor the local storage
            if (localStorageContent && localStorageContent.published.seconds !== docTimestamp.seconds) {
                window.location.reload(true);
            }
        }
    }
};

export const FirebaseCMS = async (props) => {
    const returnData = props.returnData ? props.returnData : false;
    const cmsData = await getData(props);

    // console.log('cms: getData returned', cmsData);

    return returnData ? cmsData : null;
};
